import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";
import Utils from "~/features/utils";
import { useToast } from "@component-utils/toasts";
const axios = Utils.axios

export interface AjaxController extends ExposeStimulus<typeof AjaxController> {}
export class AjaxController extends StimulusControllerBase {

  async doRequest(e: MouseEvent){
    try {
        e.preventDefault()
        const target = e.target as HTMLElement
        if(target.hasAttribute('data-clicked')) return
        const toastMessage = target.getAttribute('data-toast-message')
        if(toastMessage) {
            useToast({message: toastMessage, type: 'loading'})
        }
        const url = target.getAttribute('data-ajax-url') as string
        const method = target.getAttribute('data-method') as string
        const params = JSON.parse(target.getAttribute('data-params') as string)
        target.setAttribute('data-clicked', 'true')
        const response = await axios<{redirect: string, notice: string}>({
            url,
            method,
            params
        })
        target.removeAttribute('data-clicked')
        if(response.status === 200) {
            useToast({message: response.data.notice, type: 'success'}, {later: true})
            window.location.href = response.data.redirect
        }
    } catch (e: unknown) {
        const error = (e instanceof Error) ? e.message : e as string;
        useToast({message: error, type: 'error'})
    }
    
  }
}

export default AjaxController
